import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Outlet,
  Navigate 
} from "react-router-dom";

import FaqHome from './pages/FaqHome';
import PrivacyHome from './pages/PrivacyHome';
import TermsHome from './pages/TermsHome';

import FaqMall from './pages/FaqMall';
import PrivacyMall from './pages/PrivacyMall';
import TermsMall from './pages/TermsMall';
import Home from './pages/Home'

function App() {
  return (
    <BrowserRouter basename={'/'}>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="mall/faq" element={<FaqMall />} />
      <Route path="mall/privacy-policy" element={<PrivacyMall />} />
      <Route path="mall/terms-conditions" element={<TermsMall />} />
      <Route path="home/faq" element={<FaqHome />} />
      <Route path="home/privacy-policy" element={<PrivacyHome />} />
      <Route path="home/terms-conditions" element={<TermsHome />} />
      <Route path="*" element={<Navigate replace to="/" />} />
    </Routes>
  </BrowserRouter>
    
  );
}

export default App;
