import React, { Component }  from 'react';

const Header = () => {
    return ( 
        <nav className="navbar navbar-expand-md navbar-light fixed-top bg-main">
            <div className="container">
                <a className="navbar-brand" href="#">Summarecon Apps</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                <ul className="navbar-nav ms-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#">Tentang Kami</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#benefit">Keuntungan Apps</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#mall">FAQ</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#hubungi_kami">Hubungi Kami</a>
                    </li>
                </ul>
                </div>
            </div>
        </nav>
    );
}
 
export default Header;