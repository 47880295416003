import React from 'react'
import Header from '../components/Header';
import TentangKami from '../components/TentangKami';
import Benefit from '../components/Benefit';
import Faq from '../components/Faq';
import HubungiKami from '../components/HubungiKami';

function Home(props) {
  return (
    <div className="App">
      <Header />
      <TentangKami />
      <Benefit />
      <Faq />
      <HubungiKami />
    </div>
  );
}

export default Home