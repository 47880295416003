import React, { Component }  from 'react';

function Benefit(){

    const nav_profile_tab = (e) =>{
        e.preventDefault()

        document.querySelector(".section-icon").removeAttribute('style',true);
        document.querySelector(".nav-tabs-1").removeAttribute('style',true);
        document.querySelector(".section-icon").setAttribute('style','background-color:#F27023');
        document.querySelector(".nav-tabs-1").setAttribute('style','border-bottom: 2px solid #F27023');
	}

    const nav_phome_tab = (e) =>{
        e.preventDefault()

        document.querySelector(".section-icon").removeAttribute('style',true);
        document.querySelector(".nav-tabs-1").removeAttribute('style',true);
        document.querySelector(".section-icon").setAttribute('style','background-color:#3e5543');
        document.querySelector(".nav-tabs-1").setAttribute('style','border-bottom: 2px solid #3e5543');
        
	}
  
    return ( 
        <div className="container mt-8" id="benefit">
            <div className="row">
                <div className="col-12 mb-4">
                    <h1 className="neutral-100 text-center"><b>Features and Benefit</b></h1>
                </div>
            </div>
            <div className="section-icon">
                <div className="row">
                    <div className="col-12">
                        <nav>
                            <div className="nav nav-tabs-1 nav-justified" id="nav-tab" role="tablist">
                                <button onClick={(e) => nav_profile_tab(e)}  className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true"><h2>Summarecon Mall</h2></button>
                                <button onClick={(e) => nav_phome_tab(e)} className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false"><h2>Summarecon Home</h2></button>
                            </div>
                        </nav>
                        <div className="tab-content mt-5" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                            <div className="row">
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-rocket.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>MISSION</b></h5>
                                                <p>Raih kesempatan mendapatkan hadiah dari Misi Misi menarik</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-paid-parking.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>RESERVE PARKING</b></h5>
                                                <p>Tak perlu khawatir tidak mendapatkan parkir saat Mall sedang ramai</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-family.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>FAMILY ACCOUNT</b></h5>
                                                <p>Bersama keluarga, Anda dapat menggabungkan progress akumulasi belanja</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-win.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>REWARDS & UNDIAN</b></h5>
                                                <p>Nikmati berbagai hadiah menarik yang bisa Anda tukar setiap harinya</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-warranty.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>STAMPS</b></h5>
                                                <p>Kumpulkan stamps untuk ditukar menjadi berbagai rewards</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                <div className="row">
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-bus.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>SHUTTLE SCHEDULE</b></h5>
                                                <p>Anda dapat mengecek secara berkala jadwal Shuttle Bus di kawasan anda</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-ptz-camera-soon.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>CCTV</b></h5>
                                                <p>Anda dapat menikmati layanan CCTV untuk jalan jalan utama di kawasan anda</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-bill.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>PAYING BILLS</b></h5>
                                                <p>Nikmati fitur bayar tagihan pada aplikasi dan dapatkan Stamps setelah berhasil bayar</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-megaphone.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>REQUEST PERMIT & COMPLAINT</b></h5>
                                                <p>Anda dapat mengajukan izin dan keluhan pada aplikasi</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="tile">
                                            <div className="tile-icon">
                                                <img src={process.env.REACT_APP_CONF_S3 + "icon-package-delivery-logistics-soon.png"} />
                                            </div>
                                            <div className="desc-icon">
                                                <h5 className="mt-3"><b>PACKAGE INFO</b></h5>
                                                <p>Anda dapat mengakses informasi apabila ada Paket yang akan diantar ke tempat Anda</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
export default Benefit;