import React, { useEffect,useRef } from "react";
import axios from 'axios';
import swal from "sweetalert";
import { Link } from 'react-router-dom';

function PrivacyHome(){

    const renderAfterCalled = useRef(false);

    useEffect(() => {
        if (!renderAfterCalled.current) {
            getData()
        }
        renderAfterCalled.current = true;
    }, [])

    const getData = async () => {
        await axios.get(`${process.env.REACT_APP_API}home/privacy-policy`, {
        })
        .then(response => {
            document.querySelector("#privasi").insertAdjacentHTML("afterend",response.data);
        })
        .catch(error => {
            swal("Maaf","Terjadi Kesalahan, mohon refresh ulang.", "warning");
        })
    }

    return (
        <>
            <nav className="navbar navbar-expand-md navbar-light fixed-top bg-home">
                <div className="container">
                    <Link to="/" className="navbar-brand">Summarecon Apps</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                    </button>
                </div>
            </nav>
            <section className="page-cover pt-5 mb-5 bg-home">
                <h1 className="page-title">
                    Privacy Policy
                </h1>
            </section>
            <div className="containerfluid mb-5">
                <div className="col-md-8 offset-md-2" style={{marginTop:'-10%'}}>
                    <div className="card">
                        <div className="card-body">
                            <div class="container p-3" style={{textAlign:'justify'}} id="privasi">
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
  
export default PrivacyHome;