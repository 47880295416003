import React, { useEffect,useState,useRef } from "react";
import axios from 'axios';
import swal from "sweetalert";
import { Link } from 'react-router-dom';

function FaqHome(){

  const [data, setData] = useState([])
  const [category, setCategory] = useState([])
  const renderAfterCalled = useRef(false)

  useEffect(() => {
    if (!renderAfterCalled.current) {
      getData()
      getCategory()
    }
    renderAfterCalled.current = true;
  }, [])

  const getData = async () => {
    await axios.get(`${process.env.REACT_APP_API}home/faq`, {
    })
    .then(response => {
      setData(response.data)
    })
    .catch(error => {
      swal("Maaf","Terjadi Kesalahan, mohon refresh ulang.", "warning");
    })
  }

  const getCategory = async () => {
    await axios.get(`${process.env.REACT_APP_API}home/category-faq`, {
    })
    .then(response => {
      setCategory(response.data)
    })
    .catch(error => {
      swal("Maaf","Terjadi Kesalahan, mohon refresh ulang.", "warning");
    })
  }
  return (
    <>
      <nav className="navbar navbar-expand-md navbar-light fixed-top bg-home">
        <div className="container">
            <Link to="/" className="navbar-brand">Summarecon Apps</Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
        </div>
      </nav>
      <section className="page-cover pt-5 mb-5 bg-home">
        <h1 className="page-title">
            FAQ
        </h1>
      </section>
      <div className="containerfluid mb-5">
        <div className="col-md-8 offset-md-2" style={{marginTop:'-10%'}}>
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col-12">
                            <nav>
                                <div className="nav nav-tabs-2 tab-home nav-justified" id="nav-tab" role="tablist">
                                  <button className="nav-link active" id="nav-semua-tab" data-bs-toggle="tab" data-bs-target="#nav-semua" type="button" role="tab" aria-controls="nav-semua" aria-selected="false">
                                    Semua
                                  </button>
                                  {
                                    category.map((value, index) =>(
                                      <button className="nav-link" id={`nav-${value.name.toLowerCase()}-tab`} data-bs-toggle="tab" data-bs-target={`#nav-${value.name.toLowerCase()}`} type="button" role="tab" aria-controls={`nav-${value.name.toLowerCase()}`}aria-selected="false">
                                        {value.name}
                                      </button>
                                    ))
                                  }
                                </div>
                            </nav>
                            <div className="tab-content mt-4" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-semua" role="tabpanel" aria-labelledby="nav-semua-tab">
                                    <div className="accordion semuaExample">
                                      {
                                        data.map((value, index) =>(
                                          <div className="accordion-item">
                                            <h2 className="accordion-header" id={`semua${index}`}>
                                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsesemua${index}`} aria-expanded="false" aria-controls={`collapsesemua${index}`}>
                                                {value.question}
                                              </button>
                                            </h2>
                                            <div id={`collapsesemua${index}`} className="accordion-collapse collapse" aria-labelledby={`semua${index}`} data-bs-parent="#semuaExample">
                                              <div className="accordion-body">{value.answer}</div>
                                            </div>
                                          </div>
                                        ))
                                      }
                                    </div>
                                </div>
                                {
                                  category.map((value, index) =>(
                                    <div className="tab-pane fade" id={`nav-${value.name.toLowerCase()}`} role="tabpanel" aria-labelledby={`nav-${value.name.toLowerCase()}-tab`}>
                                      <div className="accordion" id={`${value.name.toLowerCase()}Example`}>
                                          {
                                            value.faq.map((values, indexs) =>(
                                              <div className="accordion-item">
                                                <h2 className="accordion-header" id={`${value.name.toLowerCase()}${indexs}`}>
                                                  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${value.name.toLowerCase()}${indexs}`} aria-expanded="false" aria-controls={`collapse${value.name.toLowerCase()}${indexs}`}>
                                                    {values.question}
                                                  </button>
                                                </h2>
                                                <div id={`collapse${value.name.toLowerCase()}${indexs}`} className="accordion-collapse collapse" aria-labelledby={`${value.name.toLowerCase()}${indexs}`} data-bs-parent={`#${value.name.toLowerCase()}Example`}>
                                                  <div className="accordion-body">{values.answer}</div>
                                                </div>
                                              </div>
                                            ))
                                          }
                                      </div>
                                    </div>
                                  ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </>
  );
}
  
export default FaqHome;