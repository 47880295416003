import { Link } from 'react-router-dom'
import React, { Component }  from 'react';

const Faq = () => {
    return (
        <div className="container" id="mall">
            <div className="row mt-8">
                <div className="col-12 mb-4">
                    <h1 className="neutral-100 text-center"><b>FAQ, Privacy Policy & Terms and Conditions</b></h1>
                </div>
                <div className="col-sm-12 col-lg-6 mb-2">
                    <div className="card">
                        <img src={process.env.REACT_APP_CONF_S3 +"background-summarecon-mall.png"} className="card-img-top" alt="..." />
                        <div className="card-footer bg-transparent">
                            <div className="row">
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/mall/faq" className="btn w-100 btn-orange">FAQ</Link>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/mall/privacy-policy" className="btn w-100 btn-orange">Kebijakan Privasi</Link>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/mall/terms-conditions" className="btn w-100 btn-orange">Syarat & Ketentuan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-lg-6 mb-2">
                    <div className="card">
                        <img src={process.env.REACT_APP_CONF_S3 +"background-summarecon-home.png"} className="card-img-top" alt="..." />
                        <div className="card-footer bg-transparent">
                            <div className="row">
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/home/faq" className="btn w-100 btn-green">FAQ</Link>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/home/privacy-policy" className="btn w-100 btn-green">Kebijakan Privasi</Link>
                                </div>
                                <div className="col-sm-6 col-xl-4 col-lg-6 mb-2">
                                    <Link to="/home/terms-conditions" className="btn w-100 btn-green">Syarat & Ketentuan</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
export default Faq;