import React, { useState, useEffect } from "react";
import axios from 'axios';
import swal from "sweetalert";

function HubungiKami(){

    const saltedSha256 = require('salted-sha256');
    const [values, setValues] = useState({
		name: "",
        email: "",
        number: "",
        pesan:""
	})

    const hubungiKami = (e) =>{
        e.preventDefault();
        let data = new FormData();
        let form = {
            name : values.name,
            email : values.email,
            number : values.number,
            pesan : values.pesan,
        }
        let key = saltedSha256(Math.floor(Date.now() / 1000)+' - '+saltedSha256(JSON.stringify(form),'lkdsjflekjelkrerAI'),'lksjelejrelrje');
        data.append('name',values.name);
        data.append('email',values.email);
        data.append('number',values.number);
        data.append('pesan',values.pesan);
        data.append('key',key);
       
        document.querySelector(".not_loading").setAttribute('style','display:none');
        document.querySelector(".loading").setAttribute('style','display:block');

        axios.post(`${process.env.REACT_APP_API}hubungi-kami`, data)
        .then(response => {
            if(response.data.status)
            {
                swal("Berhasil", response.data.message, "success").then((value) => {
                    window.location.reload();
                });
            }else{
                swal("Maaf", response.data.message, "warning");
                document.querySelector(".loading").setAttribute('style','display:none');
                document.querySelector(".not_loading").setAttribute('style','display:block');
            }
        })
        .catch(error => {
            swal("Maaf",error.data.message, "warning");
            document.querySelector(".loading").setAttribute('style','display:none');
            document.querySelector(".not_loading").setAttribute('style','display:block');
        });
	}

    function checkInput(e){
        return e.replace(/[^\w@.]/g, '');
    }

    return ( 
        <footer className="footer-custom" id="hubungi_kami">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="row mt-5">
                            <div className="col-12 col-lg-6">
                                <h5 className="mt-4">Alamat</h5>
                                <p>PT SATU SUMMARECON SUKSES<br/> Plaza Summarecon<br />
                                Jalan Boulevard Gading Serpong Blok M.5 <br />
                                No 3. 15810.</p>
                            </div>
                            <div className="col-12 col-lg-6">
                                <h5 className="mt-4">Kontak</h5>
                                <p>Email : <a target="_blank" href="mailto:csapps@summarecon.com">csapps@summarecon.com</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3 className="mt-5">Hubungi Kami</h3>
                        <p>Untuk informasi lebih lanjut, silahkan isi form dibawah ini untuk menghubungi tim kami</p>
                        <form>
                            <div className="row g-2">
                                <div className="col-12">
                                    <div className="form-floating">
                                    <input type="text" name="name" onChange={(e) => setValues({...values, name:checkInput(e.target.value)})} className="form-control" placeholder="Masukan Nama Anda" />
                                    <label for="floatingInputGrid">Nama Anda</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input type="email" name="email" onChange={(e) => setValues({...values, email:checkInput(e.target.value)})}  className="form-control" id="floatingInputGrid" placeholder="Masukan Nama Anda" />
                                        <label for="floatingInputGrid">Email Anda</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-floating">
                                        <input type="text" name="number" onChange={(e) => setValues({...values, number:checkInput(e.target.value)})} className="form-control" id="floatingInputGrid" placeholder="Masukan Nama Anda" />
                                        <label for="floatingInputGrid">Nomor HP Anda</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea name="pesan" className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" onChange={(e) => setValues({...values, pesan:checkInput(e.target.value)})} style={{height:'100px',resize: 'none'}}></textarea>
                                        <label for="floatingTextarea2">Masukan Pesan Anda</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button onClick={(e) => hubungiKami(e)}  className="btn btn-lg btn-white" type="button">
                                            <div className="loading" style={{display: 'none' }}>
                                                <span className="spinner-grow spinner-grow-sm"></span> Loading
                                            </div>
                                            <div className="not_loading" style={{display: 'block' }}>
                                                Kirim
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <p>
                            &copy; PT SATU SUMMARECON SUKSES {(new Date().getFullYear())}
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
}
    
export default HubungiKami;