import React, { Component }  from 'react';

const TentangKami = () => {
    return ( 
        <div className="container">
            <div className="row mt-8">
                <div className="col-lg-4 col-sm-12">
                <img src={process.env.REACT_APP_CONF_S3 + "Image.png"} className="img-fluid" alt="Description" />
                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="row">
                        <div className="col-12">
                            <h1 className="neutral-100 mb-3">
                                Kumpulkan stamp untuk di tukar dengan rewards menarik!
                            </h1>
                            <p className="neutral-80 text-justify">
                                Dengan Summarecon Apps, apapun aktivitas mu sehari hari di lingkungan Summarecon, baik dengan belanja di Summarecon Mall atau Anda sebagai warga Summarecon bisa mengumpulkan Stamps yang nantinya bisa Anda tukar dengan rewards atau hadiah menarik.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="mt-4 col-lg-6 col-sm-12">
                            <div className="card">
                                <div className="card-header bg-transparent">
                                    <div className="row">
                                        <div className="col-3">
                                            <img src={process.env.REACT_APP_CONF_S3 + "Logo-Summarecon-Mall.png"} className="img-fluid" />
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <h4 className="neutral-100">Summarecon Mall Apps</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-12">
                                        <p className="neutral-80 text-justify">
                                            TRIPLE-S dengan bangga mempersembahkan aplikasi mobile pertamanya untuk program direktori dan loyalty, yang disajikan untuk produk Summarecon Malls, di tiga kawasan antara lain Summarecon Mall Kelapa Gading, Summarecon Mall Serpong dan Summarecon Mall Bekasi.
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent">
                                    <div className="download-images">
                                        <a href="https://play.google.com/store/apps/details?id=com.summareconmall" target="_blank">
                                            <img src={process.env.REACT_APP_CONF_S3 + "google-play.png"} className="img-fluid" alt="" />
                                        </a>
                                        <a href="https://apps.apple.com/id/app/summarecon-mall/id1564420394?l=id" target="_blank">
                                            <img src={process.env.REACT_APP_CONF_S3 + "app-store.png"} className="img-fluid" alt="" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 col-lg-6  col-sm-12">
                            <div className="card">
                                <div className="card-header bg-transparent">
                                    <div className="row">
                                        <div className="col-3">
                                            <img src={process.env.REACT_APP_CONF_S3 + "Logo-Summarecon-Home.png"} className="img-fluid"/>
                                        </div>
                                        <div className="col-9 d-flex align-items-center">
                                            <h4 className="neutral-100">Summarecon Home Apps</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-12">
                                        <p className="neutral-80 text-justify">
                                        TRIPLE-S dengan bangga mempersembahkan aplikasi mobile pertamanya dengan berbagai features untuk mengoptimalkan kemudahan para warga di sekitar hunian Summarecon di tiga kawasan antara lain Summarecon Serpong, Summarecon Kelapa Gading dan Summarecon Bekasi
                                        </p>
                                    </div>
                                </div>
                                <div className="card-footer bg-transparent">
                                    <div className="download-images">
                                        <a href="https://play.google.com/store/apps/details?id=com.summareconhome" target="_blank">
                                            <img src={process.env.REACT_APP_CONF_S3 + "google-play.png"} className="img-fluid" alt=""/>
                                        </a>
                                        <a href="https://apps.apple.com/id/app/summarecon-home/id1584050086?l=id" target="_blank">
                                            <img src={process.env.REACT_APP_CONF_S3 + "app-store.png"} className="img-fluid" alt=""/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
    
export default TentangKami;